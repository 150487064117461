import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { axiosInstence, baseUrl } from "../Config/baseUrl";
// import axios from "axios";
import DOMPurify from "dompurify";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const TryCode = () => {
  const [data, setData] = useState([]);

  const ContentData = () => {
    const reqBody = new FormData();
    reqBody.append("store_id", 1);
    axiosInstence
      .get(`${baseUrl}/blog/change-page-content-api/?store_id=1`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res.data?.data || []);  // Default to empty array if no data
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    ContentData();
  }, []);

  return (
    <div>
      <div className="client_card">
        {/* Check if data exists and map it */}
        {data.length > 0 ? (
          data.map((item, index) => (
            <div key={index} className="relative inf_content">
              <h6 className="truncate-multiline w-[230px]">{item?.heading}</h6>
              <p>
                {item?.sub_heading}
                <div
                  className="p-4"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item?.content),
                  }}
                ></div>
              </p>
            </div>
          ))
        ) : (
          <p>No content available.</p> // Handle the case where data is empty
        )}
      </div>
    </div>
  );
};

export default TryCode;
