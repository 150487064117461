import React from "react";
import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <footer class="style-4 mt-50 pt-100 bgmad" data-scroll-index="8">
        <div class="container mt-50">
          <div class="section-head text-center style-4">
            <h2 class="mb-10">
              Ready To <span>Make your software with Us</span>{" "}
            </h2>
            <p>
              Discover your new favorite spaces, from delhi to USA. Contact from
              our website or given phone numbers.
            </p>
            <div class="d-flex align-items-center justify-content-center mt-50">
              <Link
                as={Link}
                to="/contact"
                rel="noreferrer"
                class="btn rounded-pill bg-blue4 fw-bold text-white me-4"
              >
                <small>Contact Us</small>
              </Link>
              <a
                href="tel:+919266667999"
                rel="noreferrer"
                class="btn rounded-pill hover-blue4 fw-bold border-blue4"
                target="_top"
              >
                <small> Call Us</small>
              </a>
            </div>
          </div>
          <div class="foot mt-10">
            <div class="row align-items-center">
              <div class="col-lg-3 col-sm-6">
                <div>
                  <h5 className="text-center cursor-pointer" onClick={() => navigate('/')}>Aara Technologies Pvt. Ltd.</h5>
                </div>
              </div>
              <div class="col-lg-8 col-sm-12 text-center">
                <ul class="links">
                  <li>
                    <Link as={Link} to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link as={Link} to="/about ">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link as={Link} to="/job-opening">
                      Career
                    </Link>
                  </li>
                  <li>
                    <Link as={Link} to="/privacy-policy">
                      Policy
                    </Link>
                  </li>
                  <li>
                    <Link as={Link} to="/ourproject">
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link as={Link} to="/blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link as={Link} to="/news">
                      News
                    </Link>
                  </li>
                  <li>
                    <Link as={Link} to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="copywrite text-center flex items-center justify-center">
            <small class=" flex items-center whitespace-nowrap gap-1">
              <LazyLoadImage
                src="https://aaratech.s3.ap-south-1.amazonaws.com/top-digital-marketing-agency-badge-square.jpg"
                className="icon-25"
              />
              <LazyLoadImage
                src="https://aaratech.s3.ap-south-1.amazonaws.com/ecommerce-development-companies-round-square-badge.png"
                className="icon-25"
              />
              © 2023 Copyrights by{" "}
              <Link
                as={Link}
                to="/contact"
                className="fw-bold text-decoration-underline"
              >
                Aara Technologies
              </Link>{" "}
              All Rights Reserved. &nbsp; &nbsp;
              <LazyLoadImage
                src="https://aaratech.s3.ap-south-1.amazonaws.com/ecommerce-development-companies-round-badge.png"
                className="icon-25"
              />
              <LazyLoadImage
                src="https://aaratech.s3.ap-south-1.amazonaws.com/top-ecommerce-development-company-badge.jpg"
                className="icon-25"
              />

              <div className=" flex items-center gap-3">
                <img src="https://aaratechnologies.com/design.png" alt="fe" className="h-8 w-auto"/>


{/* <a href="https://www.designrush.com/agency/profile/aara-technologies#reviews" target="_blank">REVIEW US ON DESIGNRUSH</a></div> */}
</div>
            </small>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
